import React from "react";
import { Card, CardContent, Typography } from "@mui/material";

import { t } from "../../lib";
import { Page } from "../../layout";

const Dashboard = () => {
  return (
    <Page
      title={t("Dashboard")}
      helmet={{ title: t("Home"), description: "TS-REACT Home" }}
    >
      {/* <Typography>Home contents</Typography> */}
      <Card>
        <CardContent>
          <Typography component="h3" gutterBottom variant="overline">
            {`DashBoard`}
          </Typography>
        </CardContent>
      </Card>
    </Page>
  );
};

export default Dashboard;

/**
 * 화면 레이아웃 처리 컴포넌트
 * 다음의 성분으로 이루어진다.
 * - 상단의 타이틀바
 * - 좌측의 메뉴바
 * - 메인 화면 영역으로 구성
 *
 * 레이아웃의 형태는 3가지
 * - FULL_WIDTH : 화면 전체를 차지하는 레이아웃
 * - PAPER : A4 Portrait 형태의 레이아웃
 * - BOX : 화면 중앙에 팝업과 같은 형태의 작은 화면 (좌측 메뉴바는 없다)
 */

import React, { useContext, useState } from "react";
import {
  AppBar,
  Box,
  Container,
  ContainerTypeMap,
  Icon,
  IconButton,
  Paper,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { Helmet } from "react-helmet-async";
import { DefaultComponentProps } from "@mui/material/OverridableComponent";
import { useHistory } from "react-router-dom";
import _ from "lodash";

import { APP, LAYOUT } from "../config/constants";
import { SessionContext, UIContext } from "../context";
import Menu from "./Menu";
import AccountBar from "./AccountBar";

interface Props {
  children: JSX.Element | JSX.Element[];
}

type Anchor = "top" | "left" | "bottom" | "right";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    height: "100%",
  },
  box: {
    display: "flex",
    alignItems: "center",
  },
  title: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  content: {
    marginLeft: 0,
  },
  contentFixedMenu: {
    marginLeft: LAYOUT.menuWidth,
  },
}));

const Layout = ({ children }: Props) => {
  const classes = useStyles();
  const isBeyondMobile = useMediaQuery("(min-width:600px)");
  const history = useHistory();
  const sessionContext = useContext(SessionContext);

  const [menuOn, setMenuOn] = useState<boolean>(false);
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleMenu = () => {
    setMenuOn(!menuOn);
  };

  const handleToggleMenu =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
      setMenuOn(!menuOn);
    };

  const handleProfile = () => {
    history.push("/profile");
  };

  const handleSignOut = (value: boolean) => {
    if (!value) return;

    sessionContext.signOut && sessionContext.signOut();

    setTimeout(() => {
      history.push("/");
    }, 0);
  };

  return (
    <UIContext.Consumer>
      {({ layout, headerLeft, title, helmet }) => {
        const containerProps: DefaultComponentProps<
          ContainerTypeMap<unknown, "div">
        > = {
          className: classes.root,
          disableGutters: false,
          maxWidth: LAYOUT.paperWidth,
        };

        const titleText = helmet?.title || title;

        const HelmetComponent = (
          <Helmet>
            <title>
              {APP.name} {titleText ? `| ${titleText}` : ""}
            </title>
            {helmet?.description && (
              <meta name="description" content={helmet?.description} />
            )}
          </Helmet>
        );

        let contentStyle;
        switch (layout) {
          case "FULL_WIDTH":
            containerProps.disableGutters = true;
            containerProps.maxWidth = false;
            contentStyle =
              LAYOUT.fixedMenu && isBeyondMobile
                ? classes.contentFixedMenu
                : classes.content;
            return (
              <Container {...containerProps}>
                {HelmetComponent}
                <AppBar
                  position={"fixed"}
                  sx={{
                    zIndex: (theme) =>
                      isBeyondMobile
                        ? theme.zIndex.drawer + 1
                        : theme.zIndex.drawer - 1,
                  }}
                >
                  <Toolbar>
                    <IconButton
                      edge="start"
                      className={classes.menuButton}
                      color="inherit"
                      aria-label="menu"
                      onClick={toggleMenu}
                    >
                      <Icon>menu</Icon>
                    </IconButton>
                    <div style={{ flexGrow: 1, paddingTop: 5, paddingLeft: 5 }}>
                      <img
                        alt="logo"
                        src="/images/white-hori.png"
                        height="35"
                      />
                    </div>
                    {isBeyondMobile && (
                      <AccountBar
                        onProfile={handleProfile}
                        onSignOut={handleSignOut}
                      />
                    )}
                  </Toolbar>
                </AppBar>
                <Menu
                  roles={_.get(sessionContext?.account, "roles")}
                  open={menuOn}
                  direction="left"
                  layout={layout}
                  onProfile={handleProfile}
                  onSignOut={handleSignOut}
                  onToggleMenu={handleToggleMenu}
                />
                <Box className={contentStyle}>
                  <Toolbar />
                  {children}
                </Box>
              </Container>
            );

          case "PAPER":
            containerProps.disableGutters = false;
            containerProps.maxWidth = LAYOUT.paperWidth;

            return (
              <Container {...containerProps}>
                {HelmetComponent}
                <Paper
                  sx={{ borderRadius: LAYOUT.headerRadius / 4 }}
                  elevation={3}
                >
                  <AppBar
                    position="static"
                    sx={{
                      borderTopRightRadius: LAYOUT.headerRadius,
                      borderTopLeftRadius: LAYOUT.headerRadius,
                    }}
                  >
                    <Toolbar>
                      <IconButton
                        edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="menu"
                        onClick={toggleMenu}
                      >
                        <Icon>menu</Icon>
                      </IconButton>
                      <Typography variant="h6" className={classes.title}>
                        {APP.name}
                      </Typography>
                      {isBeyondMobile && (
                        <AccountBar
                          onProfile={handleProfile}
                          onSignOut={handleSignOut}
                        />
                      )}
                    </Toolbar>
                  </AppBar>
                  <Menu
                    roles={_.get(sessionContext?.account, "roles")}
                    open={menuOn}
                    direction="left"
                    layout={layout}
                    onProfile={handleProfile}
                    onSignOut={handleSignOut}
                    onToggleMenu={handleToggleMenu}
                  />
                  {children}
                </Paper>
              </Container>
            );

          case "BOX":
            containerProps.className = classes.box;
            containerProps.disableGutters = false;
            containerProps.maxWidth = LAYOUT.boxWidth;

            return (
              <Container {...containerProps}>
                {HelmetComponent}
                <Paper
                  sx={{ borderRadius: LAYOUT.headerRadius / 4 }}
                  elevation={3}
                >
                  <AppBar
                    position="static"
                    sx={{
                      borderTopRightRadius: LAYOUT.headerRadius,
                      borderTopLeftRadius: LAYOUT.headerRadius,
                    }}
                  >
                    <Toolbar>
                      {headerLeft}
                      <Typography variant="h6" className={classes.title}>
                        {title}
                      </Typography>
                    </Toolbar>
                  </AppBar>
                  {children}
                </Paper>
              </Container>
            );

          case "CUSTOM":
            return (
              <Box>
                {HelmetComponent}
                {children}
              </Box>
            );

          case "NOT_FOUND":
            return <div>404 not found</div>;
          default:
            return null;
        }
      }}
    </UIContext.Consumer>
  );
};

export default Layout;

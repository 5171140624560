import en from './en';
import ko from './ko';

interface ILocale {
  [key: string]: string | number | undefined;
}

const loadLocale = (locale: string): ILocale => {
  switch (locale) {
    case 'ko':
      return ko;

    case 'en':
      return en;

    default:
      return ko;
  }
};

export type { ILocale };
export { loadLocale };

import React from "react";
import { Box, Typography } from "@mui/material";
import { IError } from "@num4sell/datasources/types";

interface Props {
  errors?: IError[] | string[];
}

const ErrorMessages = ({ errors }: Props) => {
  if (!errors || errors.length === 0) return null;

  return (
    <Box>
      {errors?.map((error, i) => (
        <Typography key={i}>
          {typeof error === "string" ? error : error.message}
        </Typography>
      ))}
    </Box>
  );
};

export default ErrorMessages;

import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  Switch,
} from "@mui/material";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

import { SERVER } from "../../config/constants";
import { IError } from "@num4sell/datasources/types";
import { findFieldError, objectToValues, t } from "../../lib";
import { InputText } from "../../components";
import { IForm } from "../../types";

interface FetchData {
  token?: string;
}

interface FetchResponse {
  data?: FetchData;
}

interface Props {
  errors?: IError[];
  input?: any;
  onInputChange: (name: string, value: any) => void;
  form?: IForm | undefined;
  type?: "c" | "r" | "u" | "i" | "e"; // create, read, update, import, export
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  item: {
    padding: theme.spacing(1),
  },
}));

const Form = ({ errors, input, onInputChange, form: setting, type }: Props) => {
  const classes = useStyles();

  const [errorMessages, setErrorMessages] = useState<IError[] | undefined>();

  useEffect(() => {
    setErrorMessages(errors);
  }, [errors]);

  const { columns } = setting;

  const CText = (column: any, type: string) => {
    return (
      <Box key={column.id} className={classes.item}>
        <InputText
          multiline
          label={t(column.label)}
          name={column.id}
          value={input?.[column.id]}
          fullWidth
          autoComplete="new-password"
          disabled={type === "r"}
          helperText={findFieldError(errorMessages, "_id")}
          onChange={(e) => onInputChange(e.target.name, e.target.value)}
          variant="standard"
        />
      </Box>
    );
  };

  const onFileChange: React.ChangeEventHandler<HTMLInputElement> = async (
    e
  ) => {
    const files = e.target.files;
    if (files && files.length === 1) {
      try {
        const file = files[0];
        const formData = new FormData();
        formData.append("file", file);
        formData.append("type", "excel");

        const response: FetchResponse = await axios({
          method: "POST",
          url: `${SERVER.url}/upload`,
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        console.log(response);

        if (response.data) {
          console.log(e.target.name, response.data);
          onInputChange(e.target.name, response.data);
        }
      } catch (e) {
        console.log("onFileChange", e);
      }
    } else {
      console.log("no file or files");
    }
  };

  const CFile = (column: any) => {
    return (
      <input
        key={`${column.id}_input`}
        type="file"
        name={column.id}
        onChange={onFileChange}
      />
    );
  };

  const CBoolean = (column: any, type: string) => {
    return (
      <Box key={column.id} className={classes.item}>
        <FormGroup>
          <FormControlLabel
            control={
              input?.[column.id] ? <Switch defaultChecked /> : <Switch />
            }
            label={t(column.label)}
            labelPlacement="start"
            name={column.id}
            value={input?.[column.id]}
            disabled={type === "r"}
            onChange={(e: any) =>
              onInputChange(e.target.name, e.target.checked)
            }
          />
        </FormGroup>
      </Box>
    );
  };

  const CSelect = (column: any, type: string) => {
    if (!column.enum) return null;
    const types = objectToValues(column.enum);
    if (!types) return null;
    console.log(types);

    return (
      <Box key={column.id} className={classes.item}>
        <Select
          name={column.id}
          value={input?.[column.id]}
          onChange={(e) => onInputChange(e.target.name, e.target.value)}
          // onChange={(e) => console.log(e)}
          // renderValue={(selected) => {
          //   const labels = selected.map((v: string) => {
          //     const item = column?.enum?.find((v2: any) => v2._id === v);
          //     return item?.name;
          //   });
          //   return labels.join(", ");
          // }}
          // disabled={type === "r"}
        >
          {types?.map((v: any) => (
            <MenuItem key={v._name} value={v.name}>
              {v.name}
            </MenuItem>
          ))}
        </Select>
      </Box>
    );
  };

  return columns?.length > 0 ? (
    <form>
      {columns.map((column) => {
        switch (column.type) {
          case "text":
            return CText(column, type);
          case "file":
            return CFile(column);
          case "label":
            return CText(column, "r");
          case "fix":
            return CText(column, "r");
          case "boolean":
            return CBoolean(column, type);
          case "select":
            return CSelect(column, type);
          default:
            return CText(column, type);
        }
      })}
    </form>
  ) : null;
};

export default Form;

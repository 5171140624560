/**
 * 화면에 대한 Context 설정을 수행한다.
 * - 레이아웃, 레이아웃에서 헤더의 유무 여부
 * - 화면의 제목
 * - 화면의 Helmet 설정
 */

import React, { createContext, ReactNode, useState } from "react";

import { UI_CONTEXT_DEFAULT } from "../config/constants";
import { IHelmet, IUIContext, LayoutType } from "../types";

interface ProviderProps {
  children: ReactNode;
}

interface ContextProps extends IUIContext {
  setValues?: (values: IUIContext) => void;
}

export const UIContext = createContext<ContextProps>(UI_CONTEXT_DEFAULT);

export const UIContextProvider = ({ children }: ProviderProps) => {
  const [layout, setLayout] = useState<LayoutType | undefined>(
    UI_CONTEXT_DEFAULT.layout
  );
  const [title, setTitle] = useState<string | undefined>("");
  const [helmet, setHelmet] = useState<IHelmet | undefined>({ title: "" });
  const [headerLeft, setHeaderLeft] = useState<
    JSX.Element | JSX.Element[] | undefined
  >();
  const [headerRight, setHeaderRight] = useState<
    JSX.Element | JSX.Element[] | undefined
  >();

  const setValues = (values: IUIContext) => {
    setLayout(values?.layout || UI_CONTEXT_DEFAULT.layout);
    setTitle(values?.title || UI_CONTEXT_DEFAULT.title);
    setHelmet(values?.helmet || UI_CONTEXT_DEFAULT.helmet);
    setHeaderLeft(values?.headerLeft || UI_CONTEXT_DEFAULT.headerLeft);
    setHeaderRight(values?.headerRight || UI_CONTEXT_DEFAULT.headerRight);
  };

  return (
    <UIContext.Provider
      value={{
        layout,
        headerLeft,
        headerRight,
        title,
        helmet,
        setValues,
      }}
    >
      {children}
    </UIContext.Provider>
  );
};

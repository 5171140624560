import Polyglot from 'node-polyglot';

import { loadLocale } from './locale';

type Locale = 'en' | 'ko';

interface OptionProps {
  [key: string]: string | number | undefined;
}

// let _polyglot = new Polyglot({ phrases: loadLocale('ko') });

export const I18n = {
  _polyglot: new Polyglot({ phrases: loadLocale('ko') }),

  setLocale: (locale: Locale) => {
    I18n._polyglot = new Polyglot({ phrases: loadLocale(locale) });
  },

  translate: (key: string, args?: OptionProps) => I18n._polyglot.t(key, args),
};

export const t = I18n.translate ? I18n.translate : (key: string) => key;

import React, { useContext } from "react";
import { useMutation } from "@apollo/client";
import { useHistory, useLocation } from "react-router-dom";

import { SessionContext } from "../../context";
import { filterGraphQLErrors } from "../../lib";
import { SIGN_IN } from "../../graphql";
import SignIn from "./SignIn";

interface LocationState {
  from: { pathname: string };
}

const SignInContainer = () => {
  const location = useLocation<LocationState>();
  const sessionContext = useContext(SessionContext);
  const history = useHistory();

  const { from } = location.state;

  const [signIn, { loading, error }] = useMutation(SIGN_IN, {
    onCompleted: (response) => {
      if (!response?.signIn) return;

      sessionContext.signIn && sessionContext.signIn(response.signIn);

      history.replace(
        !from?.pathname || from.pathname === "/sign-in"
          ? { pathname: "/" }
          : from
      );
    },
  });

  if (error) {
    console.log("signIn error", JSON.stringify(error, null, " "));
  }

  const handleForgotPassword = () => {
    history.push("/forgot-password");
  };

  const handleSubmit = (email: string, password: string) => {
    signIn({ variables: { input: { email, password } } });
  };

  return (
    <SignIn
      submitting={loading}
      errors={filterGraphQLErrors(error)}
      onForgotPassword={handleForgotPassword}
      onSubmit={handleSubmit}
    />
  );
};

export default SignInContainer;

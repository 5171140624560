import React from "react";
import { useMutation } from "@apollo/client";
import { filterGraphQLErrors } from "../../lib";
import { RESET_PASSWORD } from "../../graphql";
import ResetPassword from "./ResetPassword";
import { useRouteMatch } from "react-router-dom";

interface MatchParams {
  token: string;
}

const ResetPasswordContainer = (props: any) => {
  const match = useRouteMatch<MatchParams>("/reset-password/:token");
  const token = match?.params?.token;

  const [resetPassword, { loading, error }] = useMutation(RESET_PASSWORD, {
    onCompleted: (response) => {
      if (!response?.resetPassword) {
        alert("비밀번호 재설정에 성공했습니다.\n앱으로 돌아가 로그인 하세요.");
      }
    },
  });

  const handleSubmit = (password: string) => {
    resetPassword({ variables: { token, password } });
  };

  return (
    <ResetPassword
      submitting={loading}
      onSubmit={handleSubmit}
      errors={filterGraphQLErrors(error)}
    />
  );
};

export default ResetPasswordContainer;

import React, { useState } from 'react';
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

interface Props {
  name: string;
  value?: string;
  label?: string;
  error?: boolean;
  helperText?: string;
  fullWidth?: boolean;
  autoComplete?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputPassword = ({
  name,
  value = '',
  label,
  error,
  helperText,
  fullWidth = false,
  autoComplete = 'name',
  onChange,
}: Props) => {
  const controlId = `outlined-adornment-password-${name}`;

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
  };

  return (
    <FormControl variant="outlined" error={error} fullWidth={fullWidth}>
      <InputLabel htmlFor={controlId}>{label}</InputLabel>
      <OutlinedInput
        id={controlId}
        type={showPassword ? 'text' : 'password'}
        name={name}
        value={value}
        autoComplete={autoComplete}
        onChange={onChange}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        label={label}
      />
      {helperText && (
        <FormHelperText id="outlined-weight-helper-text">
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default InputPassword;

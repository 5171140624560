const errors = {
  error_active_role_remove_reject_message:
    'The role owned by some users can not be removed.',
  ERROR_AUTHORIZATION: 'Unauthorized accesss. check your privilege.',
  error_duplicate_key: 'The same data already exists.',
  error_input_required: 'Input required',
  error_insecure_password:
    'Password input is insecure. Use alphanumeric word with minimum 6 characters, special characters and mixed case words.',
  error_invalid_email: 'Invalid email address input',
  error_invalid_token: 'Invalid token, check and try again',
  ERROR_PASSWORD_NOT_MATCH: 'Password NOT match',
  error_sign_in: 'User not found or password not equal',
  error_sign_in_status: 'Account is NOT enabled.',
  error_length_range:
    'The length should be in the range [%{minLength}~%{maxLength}]',
};

export default errors;

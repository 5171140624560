import { gql } from "@apollo/client";

export const SYSTEM_LIST = gql`
  query SystemList {
    systemList {
      _id
      dataPublic
      dataPrivate
      createdAt
      updatedAt
    }
  }
`;

export const SYSTEM_VIEW = gql`
  query SystemView($id: ID) {
    systemView(id: $id) {
      _id
      dataPublic
    }
  }
`;

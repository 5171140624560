import React from 'react';
import { Box } from '@mui/material';

interface Props {
  children: JSX.Element | JSX.Element[] | undefined;
}
const PageContent = ({ children }: Props) => {
  return <Box>{children}</Box>;
};

export default PageContent;

const messages = {
  Actions: "Actions",
  Add: "Add",
  Address: "Address",
  AddressEmptyMessage: "No address",
  Answer: "Answer",
  AppInfo: "About App",
  AppName: "Admin",
  Author: "Author",
  Board: "Board",
  Billboard: "Billboard",
  Bookmark: "Bookmark",
  Birthday: "Birthday",
  Cancel: "Cancel",
  CardName: "Card name",
  CardNumber: "Card number",
  CAT: "Cat",
  Category: "Category",
  CategoryEditDoneMessage: "Category changed successfully.",
  CategoryNewDoneMessage: " New category registered successfully.",
  ChangePassword: "Change password",
  Claim: "Claim",
  ClaimedAt: "Claim date",
  ClaimComment: "Claim comment",
  ClaimMessage: "Claim message",
  ClaimPost: "Claim post",
  ClaimUser: "Claim user",
  Close: "Close",
  Comment: "Comment",
  Company: "Company",
  Color: "Color",
  Count: "Count",
  CreatedAt: "CreatedAt",
  CurrentPassword: "Current password",
  Date: "Date",
  Dashboard: "Dashboard",
  Delivery: "Delivery",
  DeliveryCode: "Delivery code",
  DeliveryCodeDoneMessage: "Delivery code created successfully.",
  DeliveryCompany: "Delivery company",
  DeliveryRequest: "Delivery request",
  DeliveryUrl: "Delivery url",
  Description: "Description",
  Details: "Details",
  DeliveryFee: "Delivery fee",
  DOG: "Dog",
  EditName: "Edit name",
  EngName: "English name",
  EmailAddress: "Email address",
  EmptyListMessage: "No data",
  Faq: "FAQ",
  Feature: "Feature",
  FeeThreshold: "FeeThreshold",
  Female: "Female",
  FirstName: "First name",
  ForgotPassword: "Forgot password?",
  ForgotPasswordDoneMessage: "The mail for reset password sent successfully.",
  Gender: "Gender",
  General: "General",
  GuideUrl: "Guide url",
  Hidden: "Hidden",
  HiddenDoneMessage: "Status change successfully.",
  Home: "Home",
  HomeUrl: "Home url",
  ID: "ID",
  InputBreedKeywords: "Input breed keywords",
  InputDeliveryCode: "Input delivery code",
  InputOptionName: "Input option name",
  InputOptionPrice: "Input option value price",
  InputOptionValue: "Input option value",
  Insert: "Insert",
  JuminNumber: "Resident registration number",
  Keyword: "Keywords",
  LastName: "Last name",
  List: "List",
  Like: "Like",
  Manager: "Manager",
  Male: "Male",
  Mall: "Mall",
  Mediafile: "Mediafile",
  Message: "Message",
  MileageAdd: "Mileage add",
  MileageFree: "Mileage free",
  MileageUse: "Mileage use",
  MoveToHome: "Move to Home screen",
  Name: "Name",
  NameChangedMessage: "Name changed successfully.",
  Neuter: "Neuter",
  NeuterNo: "No neuter",
  NeuterYes: "Neuter",
  NewPassword: "New password",
  NickName: "Nick name",
  No: "No",
  Notice: "Notice",
  NoMediafiles: "No mediafiles",
  Order: "Order",
  OrderCode: "Order code",
  OrderItem: "OrderItem",
  OrderPrice: " Order price",
  OrderUser: "Order user",
  OrderedAt: "OrderedAt",
  Origin: "Origin",
  OptionValueHelpMessage: "Input one value. ex)S,M,L...",
  Options: "Options",
  OptionValue: "Option value",
  OptionsPrice: "Prices",
  OptionYes: "Option yes",
  OptionNo: "Option no",
  Owner: "Owner",
  Okay: "Okay",
  Partner: "Contractor",
  Pass: "Pass",
  Password: "Password",
  PasswordChangedMessage: "Password changed successfully.",
  PasswordConfirm: "Password repeat",
  Pay: "Pay",
  PayCancel: "Pay cancel",
  PayCancelMessage: "Pay cancel?",
  PayCancelDoneMessage: "Pay cancel successfully.",
  PayMethod: "Pay method",
  PayPrice: "Pay price",
  PayTid: "Pay tid",
  PayReceiptUrl: "Pay receiptUrl",
  Phone: "Phone",
  PhoneType: "Phone type",
  PinLevel: "PinLevel",
  PinLevelChangeDoneMessage: "Pin level is changed",
  Post: "Post",
  Point: "Point",
  PostClick: "Look",
  Position: "Position",
  Price: "Price",
  Preview: "Preview",
  Product: "Product",
  ProductCode: "Code",
  ProductStatusChangeDoneMessage: "Status changed successfully.",
  ProductMileageChangeDoneMessage: "MileageFree changed successfully.",
  ProductName: "Product name",
  ProductNewDoneMessage: "New product registered successfully.",
  Profile: "Profile",
  Ready: "Ready",
  Reciver: "Reciver",
  RetailPrice: "Retail price",
  Reject: "Reject",
  RejectMessage: "Reject message",
  Remove: "Remove",
  RemoveConfirmMessage: "Are you going to remove?",
  RequiredValue: "Required",
  ResetPassword: "ResetPassword",
  ResetPasswordDoneMessage: "Password was reset successfully.",
  Role: "Role",
  RoleEditDoneMessage: "The role is updated successfully.",
  RoleNewDoneMessage: "New role registered successfully.",
  RoleRemoveConfirmMessage: "Are you going to remove the role?",
  Season: "Season",
  Share: "Share",
  SignIn: "Sign in",
  SignUp: "Sign up",
  SignOut: "Sign out",
  SignOutConfirmMessage: "Are you going to sign out?",
  SignUpDone: "Sign-up done",
  SignUpDoneMessage:
    "Signed up successfully. Check email and activate account.",
  Species: "Species",
  Staff: "Staff",
  StatDaily: "Daily stat",
  StatTotal: "Total stat",
  Status: "Status",
  StatusUpdateDoneMessage: "Status update done",
  Stock: "Stock",
  Subtitle: "Subtitle",
  Submit: "Submit",
  SupplyPrice: "Supply price",
  Supplier: "Supplier",
  System: "System",
  SystemInfo: "System information",
  SystemSettings: "System settings",
  Title: "Title",
  TotalCount: "Total count: %{count}",
  Update: "Update",
  UpdateDoneMessage: "Update done",
  Upload: "Upload",
  User: "User",
  UserActivation: "User activation",
  UserVerificationFailedMessage:
    "Account was NOT activated. The input token is invalid. Check it and try again.",
  UserVerifiedMessage: "Sign up done and activated the account.",
  Yes: "Yes",
};

export default messages;

/**
 * 회원의 로그인 기능을 구현한다.
 */

import React, { SyntheticEvent, useEffect, useState } from "react";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

import { Page } from "../../layout";
import { IError } from "@num4sell/datasources/types";
import { Button } from "@mui/material";
import { InputPassword } from "../../components";
import { t } from "../../lib";

interface Props {
  submitting: boolean;
  onSubmit: (password: string) => void;
  errors?: IError[];
}

const useStyles = makeStyles((theme: Theme) => ({
  layout: {
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#e5e5e5",
    display: "flex",
  },
  box: {
    margin: "10px",
    width: "100%",
    backgroundColor: "#fff",
  },
  logoWrap: {
    padding: "0",
    width: "100%",
    height: "100px",
    background: "linear-gradient(90deg, #2ee2b7, #2acbe1)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  logo: {
    width: "100%",
    fontSize: "22px",
    fontWeight: "bold",
    textAlign: "center",
    color: "#fff",
  },
  title: {
    width: "100%",
    fontSize: "22px",
    fontWeight: "bold",
    textAlign: "center",
    marginTop: "48px",
    marginBottom: "48px",
  },
  content: {
    // width: "calc(100% - 80px)",
    fontSize: "15px",
    textAlign: "center",
    paddingLeft: "40px",
    paddingRight: "40px",
  },
}));

const SignIn = ({ submitting, onSubmit, errors }: Props) => {
  const [errorMessages, setErrorMessages] = useState<IError[] | undefined>();
  const classes = useStyles();

  console.log(errorMessages);

  const [password, setPassword] = useState<string>("");
  const [confirm, setConfirm] = useState<string>("");

  const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    setErrorMessages(undefined);
  };

  const handleChangeConfirm = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirm(e.target.value);
    setErrorMessages(undefined);
  };

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();

    if (submitting) return;

    if (password !== confirm) {
      alert("비밀번호가 일치하지 않습니다.");
      return;
    }

    onSubmit(password);
  };

  useEffect(() => {
    setErrorMessages(errors);
  }, [errors]);

  return (
    <Page layout="CUSTOM">
      <div className={classes.layout}>
        <div className={classes.box}>
          <div className={classes.logoWrap}>
            <div className={classes.logo}>HOMEXJANG</div>
          </div>
          <div className={classes.title}>비밀번호 재설정</div>
          <div className={classes.content}>
            새로운 비빌번호를 입력해주세요.
            <br />
            <br />
            <InputPassword
              name="password"
              value={password}
              label={"비밀번호"}
              autoComplete="current-password"
              onChange={handleChangePassword}
            />
            <br />
            <br />
            <InputPassword
              name="confirm"
              value={confirm}
              label={"비밀번호 확인"}
              autoComplete="current-password"
              onChange={handleChangeConfirm}
            />
            <br />
            {errors && errors?.length > 0 && <div>{t(errors[0].message)}</div>}
            <br />
            <Button variant="outlined" color="secondary" onClick={handleSubmit}>
              재설정
            </Button>
            <br />
            <br />
            <br />
          </div>
        </div>
      </div>
    </Page>
  );
};

export default SignIn;

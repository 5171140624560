import { gql } from '@apollo/client';

export const STAT_LIST = gql`
  query StatList($searchText: String, $skip: Int, $limit: Int) {
    statList(searchText: $searchText, skip: $skip, limit: $limit) {
      _id
      totalCount
      list {
        _id
        signUp
        signIn
        animal
        post
        postLike
        postClaim
        comment
        commentLike
        commentClaim
        order
        point
        createdAt
      }
    }
  }
`;

export const DASHBOARD = gql`
  query Dashboard {
    dashboard {
      _id
      signUp
      animal
      order
      post
      postLike
      postClaim
      comment
      commentLike
      commentClaim
      order
      point
      createdAt
    }
  }
`;

export const DASHBOARD_INSPECTOR = gql`
  query Dashboard {
    dashboardInspector {
      _id
      userReady
      userReject
      userPass
      clothingReady
      clothingReject
      clothingPass
      createdAt
    }
  }
`;

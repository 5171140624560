const status = {
  CARD: '카드',
  CANCEL: '주문취소',
  KAKAO_PAY: '카카오페이',
  READY: '주문진행중',
  PAY_READY: '결제대기',
  PAID: '결제완료',
  PAID_CONFIRM: '상품 준비중',
  ON_DELIVERY: '배송중',
  DELIVERY_DONE: '배송완료',
  ORDER_DONE: '구매확정',
};

export default status;

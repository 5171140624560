import React, { useRef, useState } from "react";
import { Divider, Icon, IconButton, Toolbar, Typography } from "@mui/material";
import { HotTable } from "@handsontable/react";
import { registerAllModules } from "handsontable/registry";
import "handsontable/dist/handsontable.full.min.css";

import { Page } from "../../layout";
import { Alert, Confirm } from "../../components";
import { useMutation, useQuery } from "@apollo/client";
import {
  ADMMIN_ACTION,
  MARKET_CATEGORY_LIST,
  RESELL_CATEGORY_LIST,
  RESELL_CATEGORY_UPDATE,
} from "../../graphql";
import _ from "lodash";

registerAllModules();

const List = () => {
  const [openAlert, setOpenAlert] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openSaveConfirm, setSaveOpenConfirm] = useState(false);
  const [tempData, setTempData] = useState<any>(null);

  const { loading, data: resells } = useQuery(RESELL_CATEGORY_LIST, {
    fetchPolicy: "network-only",
    variables: { siteId: "musinsa", marketId: "coupang" },
  });

  const { loading: loading2, data: markets } = useQuery(MARKET_CATEGORY_LIST, {
    fetchPolicy: "network-only",
    variables: { marketId: "coupang" },
  });

  const resellList = resells?.resellCategoryList?.list;
  const marketList = markets?.marketCategoryList?.list;

  const [action] = useMutation(ADMMIN_ACTION, {
    onCompleted: (response) => {
      setOpenAlert(true);
      if (!response) return;
    },
  });

  const [upload] = useMutation(RESELL_CATEGORY_UPDATE, {
    onCompleted: (response) => {
      console.log(response);
      if (!response) return;
    },
  });
  //

  const handleConfirm = async (answer: boolean) => {
    setOpenConfirm(false);

    if (answer) {
      await action({ variables: { command: "SET_COUPANG_CATAGORY" } });
    }
  };

  const handleSaveConfirmClick = () => {
    const tblData = tblRef?.current?.hotInstance?.getData();
    setTempData(tblData);
    setSaveOpenConfirm(true);
  };

  const handleSaveConfirm = async (answer: boolean) => {
    setSaveOpenConfirm(false);

    if (!answer) return;

    const uploadData = getUploadData(tempData);
    console.log(uploadData);
    await upload({ variables: { input: uploadData } });
  };

  const getUploadData = (data: any) => {
    const uploadData: any = [];
    for (let index = 0; index < resellList.length; index++) {
      if (!tempData[index]) continue;
      const resell = resellList[index];
      const item: any = _.pick(resell, [
        "_id",
        "userId",
        "typeA",
        "typeACode",
        "typeB",
        "typeBCode",
      ]);

      const row = tempData[index];
      for (let ci = 2; ci < row.length; ci++) {
        if (!row[ci]) continue;
        const exists = marketList.filter(
          (market: any) =>
            market?.depth === ci - 2 && market?.[cates[ci - 2]] === row[ci]
        );

        if (exists.length === 0) continue;
        item[cates[ci - 2]] = exists[0]?.[cates[ci - 2]];
        item[cates[ci - 2] + "Code"] = exists[0]?.[cates[ci - 2] + "Code"];
      }

      uploadData.push(item);
    }

    validate(uploadData);

    return uploadData;
  };

  const validate = (items: any) => {
    for (const item of items) {
      for (let i = 4; i > 0; i--) {
        const cateNext = item[cates[i]];
        const cateBefore = item[cates[i - 1]];

        if (!cateNext && cateBefore) {
          const exists = marketList.filter(
            (market: any) =>
              market?.depth === i && market?.[cates[i - 1]] === cateBefore
          );

          if (exists) {
            console.log("!!", exists);
          }
        }
      }
    }
  };

  const data: any = [];
  if (!loading && !loading2) {
    resells?.resellCategoryList?.list?.forEach((resell: any) => {
      data.push([
        resell?.typeA,
        resell?.typeB,
        resell?.cateA,
        resell?.cateB,
        resell?.cateC,
        resell?.cateD,
        resell?.cateE,
      ]);
    });
  }

  const colHeaders = [
    "대분류",
    "중분류",
    "카테고리A",
    "카테고리B",
    "카테고리C",
    "카테고리D",
    "카테고리E",
  ];

  let currR = 0;
  const tblRef = useRef(null);
  const afterSelection = (r: number, c: number, r2: number, c2: number) => {
    if (r === r2 && c === c2) {
      currR = r;
    } else {
      currR = null;
    }
  };

  let processing = false;
  const cates = ["cateA", "cateB", "cateC", "cateD", "cateE"];
  const afterChange = (changes: any, source: any) => {
    const r = changes?.[0]?.[0];
    const c = changes?.[0]?.[1];
    const prev = changes?.[0]?.[2];
    const curr = changes?.[0]?.[3];

    if (r < 0 || c < 2) return;
    if (curr === prev) return;
    if (processing) return;

    processing = true;

    for (let i = c + 1; i < 6; i++) {
      tblRef?.current?.hotInstance?.setDataAtCell(r, i, null);
    }

    if (curr === null || curr === "") return;

    const cate = marketList?.filter(
      (market: any) =>
        market?.depth === c - 2 && market?.[cates[c - 2]] === curr
    );

    for (let i = 2; i < c; i++) {
      tblRef?.current?.hotInstance?.setDataAtCell(
        r,
        i,
        cate[0]?.[cates[i - 2]]
      );
    }

    setTimeout(() => {
      processing = false;
    }, 1000);
  };

  const autoComplete = (depth: number, query: any, process: any) => {
    const list: any = [];
    const currRow = tblRef?.current?.hotInstance?.getDataAtRow(currR);

    console.log(181, depth, currRow, currRow[depth + 1]);

    if (depth > 0 && currRow[depth + 1] !== null && currRow[depth + 1] !== "") {
      marketList?.forEach((market: any) => {
        if (
          market?.depth === depth &&
          market?.[cates[depth - 1]] === currRow[depth + 1]
        ) {
          list.push(market?.[cates[depth]]);
        }
      });
    } else {
      marketList?.forEach((market: any) => {
        if (market?.depth === depth) {
          list.push(market?.[cates[depth]]);
        }
      });
    }

    process(list);
  };

  const columns = [
    { type: "text", readOnly: true },
    { type: "text", readOnly: true },
    {
      type: "autocomplete",
      source: (query: any, process: any) => autoComplete(0, query, process),
      visibleRows: 10,
      // strict: true,
    },
    {
      type: "autocomplete",
      source: (query: any, process: any) => autoComplete(1, query, process),
      visibleRows: 10,
      // strict: true,
    },
    {
      type: "autocomplete",
      source: (query: any, process: any) => autoComplete(2, query, process),
      visibleRows: 10,
      // strict: true,
    },
    {
      type: "autocomplete",
      source: (query: any, process: any) => autoComplete(3, query, process),
      visibleRows: 10,
      // strict: true,
    },
    {
      type: "autocomplete",
      source: (query: any, process: any) => autoComplete(4, query, process),
      visibleRows: 10,
      // strict: true,
    },
  ];

  // const rowData: any = loading ? [] : data?.resellCategoryList?.list;

  return loading || loading2 ? null : (
    <Page
      title={"카테고리 | 쿠팡"}
      pageTitle={
        <Typography variant="h4">
          카테고리 <small>쿠팡</small>
        </Typography>
      }
    >
      {/* <Toolbar variant="dense">
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={() => setOpenConfirm(true)}
        >
          <Icon>cloud_download</Icon>
        </IconButton>
      </Toolbar> */}
      <Toolbar variant="dense">
        <IconButton
          edge="start"
          color="inherit"
          // aria-label="menu"
          onClick={handleSaveConfirmClick}
        >
          <Icon>save</Icon>
        </IconButton>
      </Toolbar>
      <Divider />
      <HotTable
        ref={tblRef}
        licenseKey="non-commercial-and-evaluation"
        data={tempData ? tempData : data}
        colHeaders={colHeaders}
        columns={columns}
        colWidths={[140, 140, 140, 140, 140, 140, 140]}
        manualColumnResize={true}
        afterSelection={afterSelection}
        afterChange={afterChange}
      />
      <Confirm
        open={!!openConfirm}
        title={`알림`}
        message={"쿠팡에서 최신 카테고리를 가져오시겠습니까?"}
        onClose={handleConfirm}
      />
      <Confirm
        open={!!openSaveConfirm}
        title={`알림`}
        message={"저장할까요?"}
        onClose={handleSaveConfirm}
      />
      <Alert
        open={!!openAlert}
        title={`알림`}
        message={"쿠팡에서 최신 카테고리를 가져왔습니다."}
        onClose={() => setOpenAlert(false)}
      />
    </Page>
  );
};

export default List;

import { gql } from "@apollo/client";

export const EVENT_LIST = gql`
  query EventList($searchText: String, $skip: Int, $limit: Int) {
    eventList(searchText: $searchText, skip: $skip, limit: $limit) {
      _id
      totalCount
      list {
        _id
        cmd
        param
        status
        target
        changed
        createdAt
        updatedAt
      }
    }
  }
`;

export const EVENT_VIEW = gql`
  query EventView($id: ID) {
    eventView(id: $id) {
      _id
      cmd
      param
      status
      target
      changed
      createdAt
      updatedAt
    }
  }
`;

export const EVENT_NEW = gql`
  mutation EventNew($input: EventInput) {
    eventNew(input: $input) {
      _id
      cmd
      param
      status
      target
      changed
      createdAt
      updatedAt
    }
  }
`;

export const EVENT_UPDATE = gql`
  mutation EventUpdate($id: ID, $input: EventInput) {
    eventUpdate(id: $id, input: $input) {
      _id
      cmd
      param
      status
      target
      changed
      createdAt
      updatedAt
    }
  }
`;

export const EVENT_REMOVE = gql`
  mutation EventRemove($id: ID) {
    eventRemove(id: $id)
  }
`;

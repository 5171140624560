import React from 'react';
import { CircularProgress, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 15,
    marginBottom: 15,
    textAlign: 'center',
  },
});

const Loading = () => {
  const classes = useStyles();

  return (
    <Container className={classes.container} disableGutters>
      <CircularProgress />
    </Container>
  );
};

export default Loading;

import { gql } from "@apollo/client";

export const ROLE_LIST = gql`
  query RoleList($searchText: String, $skip: Int, $limit: Int) {
    roleList(searchText: $searchText, skip: $skip, limit: $limit) {
      _id
      totalCount
      list {
        _id
        name
        desc
        createdAt
        updatedAt
      }
    }
  }
`;

export const ROLE_VIEW = gql`
  query RoleView($id: ID) {
    roleView(id: $id) {
      _id
      name
      desc
      createdAt
      updatedAt
    }
  }
`;

export const ROLE_NEW = gql`
  mutation RoleNew($input: RoleInput) {
    roleNew(input: $input) {
      _id
      name
      desc
      createdAt
      updatedAt
    }
  }
`;

export const ROLE_UPDATE = gql`
  mutation RoleUpdate($id: ID, $input: RoleInput) {
    roleUpdate(id: $id, input: $input) {
      _id
      name
      desc
      createdAt
      updatedAt
    }
  }
`;

export const ROLE_REMOVE = gql`
  mutation RoleRemove($id: ID) {
    roleRemove(id: $id)
  }
`;

/**
 * 회원의 로그인 기능을 구현한다.
 */

import React, { SyntheticEvent, useEffect, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

import { Page } from "../../layout";
import { IError } from "@num4sell/datasources/types";
import { t } from "../../lib";
import { InputPassword, InputText } from "../../components";

interface Props {
  submitting: boolean;
  errors?: IError[];
  onForgotPassword: () => void;
  onSubmit: (email: string, password: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  forgotPassword: {
    textAlign: "right",
    justifyContent: "flex-end",
  },
}));

const SignIn = ({
  submitting,
  errors,
  onForgotPassword,

  onSubmit,
}: Props) => {
  const classes = useStyles();

  const [errorMessages, setErrorMessages] = useState<IError[] | undefined>();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  useEffect(() => {
    setErrorMessages(errors);
  }, [errors]);

  const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setErrorMessages(undefined);
  };

  const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    setErrorMessages(undefined);
  };

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();

    if (submitting) return;

    onSubmit(email || "", password || "");
  };

  return (
    <Page layout="BOX" title={t("SignIn")}>
      <form>
        <Grid container spacing={2}>
          {errorMessages && errorMessages.length > 0 && (
            <Grid item xs={12}>
              <Typography>
                {errorMessages.map((doc) => t(doc.message))}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <InputText
              name="email"
              value={email}
              label={t("EmailAddress")}
              fullWidth
              autoComplete="username"
              onChange={handleChangeEmail}
            />
          </Grid>
          <Grid item xs={12}>
            <InputPassword
              name="password"
              value={password}
              label={t("Password")}
              fullWidth
              autoComplete="current-password"
              onChange={handleChangePassword}
            />
            <Box className={classes.forgotPassword}>
              <Button variant="text" onClick={onForgotPassword}>
                {t("ForgotPassword")}
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <LoadingButton
              loading={submitting}
              variant="contained"
              fullWidth
              onClick={handleSubmit}
            >
              {t("SignIn")}
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </Page>
  );
};

export default SignIn;

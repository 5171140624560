import React from 'react';
import { Box } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

interface Props {
  children?: JSX.Element | JSX.Element[];
  left?: JSX.Element | JSX.Element[];
  right?: JSX.Element | JSX.Element[];
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  left: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const PageHeader = ({ left, right, children }: Props) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.left}>
        {left}
        {children}
      </Box>
      <Box>{right}</Box>
    </Box>
  );
};

export default PageHeader;

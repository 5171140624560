import axios from 'axios';
import { ErrorResponse } from '@apollo/client/link/error';
import { fromPromise } from '@apollo/client/link/utils';
import { FetchResult } from '@apollo/client/link/core';
import { Observable } from '@apollo/client/utilities';

import { LOCAL_STORAGE_KEY, SERVER } from '../config/constants';

interface FetchData {
  token?: string;
}

interface FetchResponse {
  data?: FetchData;
}

/**
 * 서버로 RefreshToken을 보내서 갱신된 AccessToken을 받아 localStorage에 저장한다.
 *
 * @param refreshToken
 */
const refreshTokenFetch = async () => {
  try {
    const refreshToken: string | null = localStorage.getItem(
      LOCAL_STORAGE_KEY.REFRESH_TOKEN,
    );
    if (!refreshToken) return false;

    const response: FetchResponse = await axios({
      method: 'get',
      url: `${SERVER.url}/refresh-token/${refreshToken}`,
    });

    if (response?.data?.token) {
      localStorage.setItem(
        LOCAL_STORAGE_KEY.ACCESS_TOKEN,
        response?.data?.token,
      );
      return true;
    }
  } catch (ex) {
    // console.log('refreshTokenFetch exception', ex);
  }
  localStorage.removeItem(LOCAL_STORAGE_KEY.REFRESH_TOKEN);
  localStorage.removeItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN);
  return false;
};

/**
 * 서버에 AccessToken을 갱신요청하여 받은 후, 이전의 요청을 다시 보낸다.
 *
 * @param refreshToken
 * @param param1
 * @returns
 */
export const refreshTokenHandler = ({
  operation,
  forward,
}: ErrorResponse): Observable<FetchResult> => {
  return fromPromise(
    refreshTokenFetch()
      .then(() => forward(operation))
      .catch((ex) => {
        console.log('refreshTokenFetch ex', ex);
        return ex;
      }),
  )
    .filter((value) => Boolean(value))
    .flatMap(() => forward(operation));
};

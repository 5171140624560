/**
 * 회원의 로그인 기능을 구현한다.
 */

import React, { useEffect, useState } from "react";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

import { Page } from "../../layout";
import { IError } from "@num4sell/datasources/types";

interface Props {
  submitting: boolean;
  errors?: IError[];
}

const useStyles = makeStyles((theme: Theme) => ({
  layout: {
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#e5e5e5",
    display: "flex",
  },
  box: {
    margin: "10px",
    width: "100%",
    backgroundColor: "#fff",
  },
  logoWrap: {
    padding: "0",
    width: "100%",
    height: "100px",
    background: "linear-gradient(90deg, #2ee2b7, #2acbe1)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  logo: {
    width: "100%",
    fontSize: "22px",
    fontWeight: "bold",
    textAlign: "center",
    color: "#fff",
  },
  title: {
    width: "100%",
    fontSize: "22px",
    fontWeight: "bold",
    textAlign: "center",
    marginTop: "48px",
    marginBottom: "48px",
  },
  content: {
    // width: "calc(100% - 80px)",
    fontSize: "15px",
    textAlign: "center",
    paddingLeft: "40px",
    paddingRight: "40px",
  },
}));

const SignIn = ({ submitting, errors }: Props) => {
  const [errorMessages, setErrorMessages] = useState<IError[] | undefined>();
  const classes = useStyles();

  console.log(errorMessages);

  useEffect(() => {
    setErrorMessages(errors);
  }, [errors]);

  return (
    <Page layout="CUSTOM">
      <div className={classes.layout}>
        <div className={classes.box}>
          <div className={classes.logoWrap}>
            <div className={classes.logo}>HOMEXJANG</div>
          </div>
          <div className={classes.title}>사용자 메일 인증</div>
          <div className={classes.content}>
            인증되었습니다.
            <br />
            앱으로 돌아가 로그인 후 회원 가입을 마무리 하세요.
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      </div>
    </Page>
  );
};

export default SignIn;

import { MenuDummy } from "../types";

const menu: MenuDummy[] = [
  {
    type: "list",
    list: [
      {
        title: "Dashboard",
        path: "/",
        icon: "dashboard",
      },
    ],
    roles: ["ROLE_ROOT", "ROLE_STAFF", "ROLE_PARTNER"],
  },
  {
    type: "divider",
  },
  {
    type: "list",
    list: [
      {
        title: "User",
        children: [
          {
            title: "User",
            path: "/users",
            icon: "person",
          },
        ],
        icon: "person",
      },
    ],
    roles: ["ROLE_ROOT", "ROLE_STAFF", "ROLE_PARTNER"],
  },
  {
    type: "divider",
  },
  {
    type: "list",
    list: [
      {
        title: "카테고리",
        children: [
          {
            title: "무신사 카테고리",
            path: "/musinsaCategory",
            icon: "category",
          },
        ],
        icon: "person",
      },
    ],
    roles: ["ROLE_ROOT", "ROLE_STAFF", "ROLE_PARTNER"],
  },
  {
    type: "divider",
  },
  {
    type: "list",
    list: [
      {
        title: "관리",
        children: [
          {
            title: "이벤트",
            path: "/events",
            icon: "settings",
          },
        ],

        icon: "folder",
      },
    ],
    roles: ["ROLE_ROOT"],
  },
  {
    type: "divider",
  },
  {
    type: "list",
    list: [
      {
        title: "System",
        children: [
          {
            title: "SystemSettings",
            path: "/system",
            icon: "settings",
          },
          {
            title: "Role",
            path: "/roles",
            icon: "lock",
          },
        ],

        icon: "folder",
      },
    ],
    roles: ["ROLE_ROOT"],
  },
];

export default menu;

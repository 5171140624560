import { gql } from "@apollo/client";

export const MARKET_CATEGORY_LIST = gql`
  query MarketCategoryList($marketId: String) {
    marketCategoryList(marketId: $marketId) {
      _id
      totalCount
      list {
        _id
        depth
        cateA
        cateACode
        cateB
        cateBCode
        cateC
        cateCCode
        cateD
        cateDCode
        cateE
        cateECode
        createdAt
        updatedAt
      }
    }
  }
`;

export const RESELL_CATEGORY_LIST = gql`
  query ResellCategoryList($siteId: String, $marketId: String) {
    resellCategoryList(siteId: $siteId, marketId: $marketId) {
      _id
      totalCount
      list {
        _id
        userId
        marketId
        siteId
        typeName
        typeCode
        typeA
        typeACode
        typeB
        typeBCode
        typeC
        typeCCode
        typeD
        typeDCode
        typeE
        typeECode
        cateName
        cateCode
        cateA
        cateACode
        cateB
        cateBCode
        cateC
        cateCCode
        cateD
        cateDCode
        cateE
        cateECode
        createdAt
        updatedAt
      }
    }
  }
`;

export const RESELL_CATEGORY_UPDATE = gql`
  mutation ResellCategoryUpdate($input: [ResellCategoryInput]) {
    resellCategoryUpdate(input: $input)
  }
`;

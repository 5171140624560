import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import { t } from "../../lib";
import View from "./View";
import { IForm } from "../../types";
import _ from "lodash";

interface Props {
  open: boolean;
  onClose: () => void;
  form?: IForm | undefined;
  refetch?: any;
  type?: "c" | "r" | "u";
  id?: string;
}

const ViewContainer = ({
  open,
  onClose,
  form,
  refetch,
  type: _type,
  id,
}: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [type, setType] = useState<"c" | "r" | "u">(_type);

  useEffect(() => {
    setType(_type);
  }, [id, _type]);

  const [formC] = useMutation(form.crud.c, {
    onCompleted: (response) => {
      if (!response) return;

      onClose();
      enqueueSnackbar(t("DoneMessage"), { variant: "success" });
    },
  });

  const [formU] = useMutation(form.crud.u, {
    onCompleted: (response) => {
      console.log(response);
      if (!response) return;

      onClose();
      enqueueSnackbar(t("DoneMessage"), { variant: "success" });
    },
  });

  const handleSubmit = async (_input: any | undefined) => {
    console.log(_input);
    const input = _.omit(_input, ["_id", "updatedUser"]);
    if (type === "c") await formC({ variables: { input } });
    if (type === "u") await formU({ variables: { id, input } });

    await refetch();
  };

  const _data = useQuery(form.crud.r, {
    fetchPolicy: "network-only",
    variables: { id },
  });

  let data = null;
  if (_data && !_data.loading && _data.data) {
    const keys = Object.keys(_data.data);
    if (keys.length === 1) data = _data.data[keys[0]];
    data = _.omit(data, ["__typename", "createdAt", "updatedAt"]);
  }

  return !_data.loading ? (
    <View
      data={data}
      type={type}
      open={open}
      onSubmit={handleSubmit}
      onClose={onClose}
      form={form}
      setType={setType}
    />
  ) : null;
};

export default ViewContainer;

import React from "react";
import { TextField, TextFieldProps } from "@mui/material";
import _ from "lodash";

/**
 * 속성에서 `id`를 지정하지 않으면 `name` 속성을 `id` 속성에 지정한다.
 *
 * @param props
 * @returns
 */
const InputText = (_props: TextFieldProps) => {
  const props = _.omit(_props, "disabled");
  const inputProps: any = { ...props, variant: "outlined" };

  const id = props?.id;
  let myProps = id ? { ...inputProps, id } : { ...inputProps, id: props.name };

  //inputProps:{{ readOnly: true }}

  if (_props.disabled) {
    myProps = {
      ...myProps,
      inputProps: { readOnly: true },
      variant: "standard",
      // multiline: true,
      // maxRows: 4,
    };

    //        id="standard-multiline-flexible"
    // label="Multiline"
    // multiline
    // maxRows={4}
    // value={_props.value}
    // variant="standard"
    return <TextField multiline {...myProps} />;
  } else return <TextField {...myProps} />;
};

export default InputText;

import React, { useState } from "react";
import { Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { IError } from "@num4sell/datasources/types";
import { t } from "../../lib";
import { Modal } from "../../components";
import Form from "./Form";
import { IForm } from "../../types";

interface Props {
  data: any;
  open: boolean;
  loading?: boolean;
  errors?: IError[];
  onSubmit: (input: any | undefined) => void;
  onClose: () => void;
  form?: IForm | undefined;
  type?: "c" | "r" | "u";
  setType: any;
}

const View = ({
  data,
  open,
  loading,
  errors,
  onSubmit,
  onClose,
  form,
  type,
  setType,
}: Props) => {
  const initData: any = {};
  const { columns } = form;
  columns?.forEach((column: any) => {
    if (column.type === "fix" && column.value) {
      initData[column.id] = column.value;
    }
  });

  const [input, setInput] = useState<any>({ ...data, ...initData });

  const handleInputChange = (name: string, value: any) => {
    setInput({ ...input, [name]: value });
  };

  const handleSubmit = () => {
    if (!loading) {
      console.log(50505050);
      onSubmit(input);
    }
  };
  const getTypeText = () => {
    switch (type) {
      case "c":
        return t("Insert");
      case "r":
        return t("Details");
      case "u":
        return t("Update");
    }
  };

  return (
    <Modal
      open={open}
      title={`${t(form.name)} ${getTypeText()}`}
      Actions={
        <>
          {type === "r" ? (
            <React.Fragment>
              <Button onClick={onClose}>{t("Close")}</Button>
              <LoadingButton fullWidth={false} onClick={() => setType("u")}>
                {t("Update")}
              </LoadingButton>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Button onClick={() => setType("r")}>{t("Cancel")}</Button>
              <LoadingButton fullWidth={false} onClick={handleSubmit}>
                {t("Submit")}
              </LoadingButton>
            </React.Fragment>
          )}
        </>
      }
      onClose={onClose}
    >
      <Form
        type={type}
        errors={errors}
        form={form}
        input={input}
        onInputChange={handleInputChange}
      />
    </Modal>
  );
};

export default View;

import React from "react";
import { Typography } from "@mui/material";

import { USER_LIST } from "../../graphql";
import { Table } from "../../components";
import { t } from "../../lib";
import { Page } from "../../layout";

const UserList = () => {
  const headers = [
    { id: "name", label: "Name" },
    {
      id: "email",
      label: "EmailAddress",
      filter: true,
      param: "email",
    },
    {
      id: "phone",
      label: "Phone",
      filter: true,
      type: "phone",
      param: "phone",
    },
    { id: "referer", label: "Referer" },
    { id: "createdAt", label: "CreatedAt", sort: true, type: "date" },
    { id: "actions", label: "Actions", type: "action", url: "/user" },
  ];

  return (
    <Page
      title={t("User")}
      pageTitle={
        <Typography variant="h4">
          {t("User")} <small>{t("List")}</small>
        </Typography>
      }
    >
      <Table headers={headers} query={{ userList: USER_LIST }} />
    </Page>
  );
};

export default UserList;

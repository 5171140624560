import React from "react";
import { Typography } from "@mui/material";

import {
  ROLE_LIST,
  ROLE_VIEW,
  ROLE_NEW,
  ROLE_REMOVE,
  ROLE_UPDATE,
} from "../../graphql";
import { Table } from "../../components";
import { t } from "../../lib";
import { Page } from "../../layout";

const List = () => {
  const headers = [
    { id: "_id", label: "ID" },
    { id: "name", label: "Name" },
    { id: "desc", label: "Description" },
  ];

  const form = {
    name: "Role",
    crud: {
      c: ROLE_NEW,
      r: ROLE_VIEW,
      u: ROLE_UPDATE,
      d: ROLE_REMOVE,
    },
    columns: [
      { id: "_id", label: "ID" },
      { id: "name", label: "Name" },
      { id: "desc", label: "Description" },
    ],
  };

  return (
    <Page
      title={t("Role")}
      pageTitle={
        <Typography variant="h4">
          {t("Role")} <small>{t("List")}</small>
        </Typography>
      }
    >
      <Table headers={headers} form={form} query={{ roleList: ROLE_LIST }} />
    </Page>
  );
};

export default List;

import React, { ChangeEvent } from "react";
import { Link as RouterLink, useHistory, useLocation } from "react-router-dom";
import {
  Icon,
  IconButton,
  Pagination,
  TableBody,
  TableCell,
  TableFooter,
  TableRow,
  Switch,
  Link,
} from "@mui/material";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import _ from "lodash";
import QueryString from "qs";

import { IError, IPagination, ICondition } from "@num4sell/datasources/types";
import { IHeader } from "../../types";
import { getDate, getPhone } from "../../lib";
import { ErrorMessages } from "..";

interface Props {
  loading: boolean;
  errors: IError[] | undefined;
  headers: IHeader[] | undefined;
  // data: IList<IUser>;
  data: any;
  pointSort: string | undefined;
  dateSort: string | undefined;
  search: ICondition | undefined;
  onPointSort: () => void;
  onSort: () => void;
  onSearch: (id: string, value: string) => void;
  onPaginationChange: (pagination: IPagination) => void;
  onRefetch: () => void;
  pagination: IPagination;
  setPagination: any;
}

const useStyles = makeStyles((theme: Theme) => ({
  pagination: {
    flexDirection: "row",
    justifyContent: "flex-end",
    padding: theme.spacing(1),
  },
}));

const getValue = (header: any, doc: any) => {
  let value = doc;
  value = _.get(doc, header.id);

  if (header.ids && header.ids.length > 0) {
    header.ids.forEach((_id: string) => {
      if (!value) value = _.get(doc, _id);
    });
  }

  if (header.convert) {
    value = header.convert(value);
  }

  switch (header.type) {
    case "date":
      value = getDate(value, "YYYY-MM-DD");
      break;

    case "phone":
      value = getPhone(value);
      break;

    default:
      break;
  }

  if (header.width) {
    value = (
      <div
        style={{
          width: header.width,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {value}
      </div>
    );
  }

  return value;
};

const Body = ({
  loading,
  errors,
  headers,
  data,
  pointSort,
  dateSort,
  search,
  onPointSort,
  onSort,
  onSearch,
  onPaginationChange,
  onRefetch,
  pagination,
  setPagination,
}: Props) => {
  const history = useHistory();
  const { totalCount, list } = data || {};
  const hasErrors = !!(errors && errors.length > 0);

  const location = useLocation();
  const queryData: any = QueryString.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  // const page = queryData?.page ? parseInt(queryData?.page) : 1;

  // const [pagination, setPagination] = useState<IPagination>({
  //   rowsPerPageOptions: [10, 25, 50],
  //   rowsPerPage: 10,
  //   count: data?.totalCount || 0,
  //   page,
  // });

  const classes = useStyles();

  const handlePageChange = (event: ChangeEvent<unknown>, page: number) => {
    const qd = queryData;
    qd.page = page;

    history.push(`${location.pathname}?${QueryString.stringify(qd)}`);
    const newPagination = { ...pagination, page };
    setPagination(newPagination);
    onPaginationChange(newPagination);
  };

  const handleToggle = (cb: any, id: string, e: any) => {
    const result = cb(id, e?.target);
    if (result) {
      onRefetch();
    }
  };

  const Body = () => {
    return (
      <TableBody>
        {!totalCount || totalCount === 0 ? (
          <TableRow>
            <TableCell colSpan={headers.length}>Empty List</TableCell>
          </TableRow>
        ) : (
          list?.map((doc: any) => (
            <TableRow key={doc._id}>
              {headers &&
                headers.map((header) => {
                  switch (header.type) {
                    case "toggle":
                      return header.cb ? (
                        <TableCell key={header.id}>
                          <Switch
                            checked={getValue(header, doc)}
                            edge="start"
                            name={header.id}
                            onChange={(e) =>
                              handleToggle(header.cb, doc?._id, e)
                            }
                          />
                        </TableCell>
                      ) : (
                        <TableCell key={header.id}>
                          <Switch
                            checked={getValue(header, doc)}
                            edge="start"
                            name={header.id}
                          />
                        </TableCell>
                      );
                    case "check":
                      return getValue(header, doc) ? (
                        <TableCell key={header.id}>
                          <Icon>check</Icon>
                        </TableCell>
                      ) : (
                        <TableCell key={header.id}>
                          {/* <Icon>check</Icon> */}
                        </TableCell>
                      );

                    case "action":
                      return (
                        <TableCell key={header.id}>
                          <IconButton
                            onClick={() => {
                              if (header.url)
                                history.push(`${header.url}/${doc._id}`);
                              if (header.cb) header.cb(doc._id);
                            }}
                          >
                            {header.label === "Remove" ? (
                              <Icon>delete</Icon>
                            ) : (
                              <Icon>edit</Icon>
                            )}
                          </IconButton>
                        </TableCell>
                      );

                    default:
                      if (header.url && header.path) {
                        return (
                          <TableCell key={header.id}>
                            <Link
                              component={RouterLink}
                              to={`${header.url}/${_.get(doc, header.path)}`}
                            >
                              {getValue(header, doc)}
                            </Link>
                          </TableCell>
                        );
                      } else if (header.url) {
                        return (
                          <TableCell key={header.id}>
                            <Link
                              component={RouterLink}
                              to={`${header.url}/${doc._id}`}
                            >
                              {getValue(header, doc)}
                            </Link>
                          </TableCell>
                        );
                      } else {
                        return (
                          <TableCell key={header.id}>
                            {getValue(header, doc)}
                          </TableCell>
                        );
                      }
                  }
                })}
            </TableRow>
          ))
        )}
      </TableBody>
    );
  };
  const Footer = () => {
    return (
      <TableFooter>
        <TableRow>
          <TableCell colSpan={headers.length}>
            <Pagination
              className={classes.pagination}
              count={Math.ceil(totalCount / pagination.rowsPerPage)}
              defaultPage={pagination.page}
              showFirstButton
              showLastButton
              onChange={handlePageChange}
            />
          </TableCell>
        </TableRow>
      </TableFooter>
    );
  };

  // if (loading) return <Loading />;
  if (loading) return null;
  if (hasErrors) return <ErrorMessages errors={errors} />;

  return (
    <>
      <Body />
      <Footer />
    </>
  );
};

export default Body;

import SignIn from "../modules/auth/SignInContainer";

import Dashboard from "../modules/dashboard/DashboardContainer";
import EventList from "../modules/list/EventList";
import RoleList from "../modules/list/RoleList";
import UserList from "../modules/list/UserList";
import MusinsaCategoryGrid from "../modules/grid/MusinsaCategoryGrid";

import VerifyUser from "../modules/auth/VerifyUserContainer";
import ResetPassword from "../modules/auth/ResetPasswordContainer";

export const routes = [
  // { path: "/", exact: true, Component: Home },
  { path: "/sign-in", exact: true, Component: SignIn },
  { path: "/verify-user/:token", exact: false, Component: VerifyUser },
  { path: "/reset-password/:token", exact: false, Component: ResetPassword },
];

export const privateRoutes = [
  { path: "/", exact: true, Component: Dashboard },
  { path: "/users", exact: true, Component: UserList },
  { path: "/events", exact: true, Component: EventList },
  { path: "/roles", exact: true, Component: RoleList },
  { path: "/musinsaCategory", exact: true, Component: MusinsaCategoryGrid },
];

const status = {
  CARD: 'Pay card',
  CANCEL: 'Order cancel',
  KAKAO_PAY: 'Pay Kakaopay',
  READY: 'Ready',
  PAY_READY: 'Pay ready',
  PAID: 'Paid',
  PAID_CONFIRM: 'Paid confirm',
  ON_DELIVERY: 'Delivery',
  DELIVERY_DONE: 'Delivery done',
  ORDER_DONE: 'Order done',
};

export default status;

import { t } from "./index";

const phoneFormat = (num: string) => {
  if (num.length === 11) {
    num = num.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
  } else if (num.length === 8) {
    num = num.replace(/(\d{4})(\d{4})/, "$1-$2");
  } else {
    if (num.indexOf("02") === 0) {
      num = num.replace(/(\d{2})(\d{4})(\d{4})/, "$1-$2-$3");
    } else {
      num = num.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
    }
  }

  return num;
};

export const getPhone = (value: string) => {
  if (!value || !value.startsWith) return value;
  if (value.startsWith("+")) value = value.substring(3);
  if (value.startsWith("1")) value = `0${value}`;
  return phoneFormat(value);
};

export const getJumin = (value: string) => {
  if (value?.length === 13) {
    value = value.replace(/(\d{6})(\d{7})/, "$1-$2");
  }
  return value;
};

export const Seasons = [
  { _id: "spring&fall", name: "봄/가을" },
  { _id: "summer", name: "여름" },
  { _id: "winter", name: "겨울" },
];

export const getSeason = (id: string) => {
  const season = Seasons.find((e) => id === e._id);
  if (season?.name) return season.name;
  else return "-";
};

export const getInspectionStatus = (status: string) => {
  switch (status) {
    case "READY":
      return t("Ready");
    case "PASS":
      return t("Pass");
    case "REJECT":
      return t("Reject");
    case "COMPLETE":
      return t("Complete");
  }
};

export const getPose = (pose: string) => {
  switch (pose) {
    case "front":
      return "정면";
    case "back":
      return "후면";
    case "side":
      return "착용 정측면";
    case "wearFront":
      return "착용 정면";
    case "wearBack":
      return "착용 후면";
  }
};

export const getWashing = (type: string) => {
  switch (type) {
    case "water":
      return "물세탁 방법";
    case "bleaching":
      return "표백";
    case "ironing":
      return "다림질";
    case "weave":
      return "짜는 방법";
    case "dryCleaning":
      return "드라이 클리닝";
    case "drying":
      return "건조 방법";
  }
};

export const getRejectType = (pose: string) => {
  switch (pose) {
    case "MEDIA_COMMON":
      return "공통";
    case "MEDIA_CLOTHING":
      return "의류";
    case "MEDIA_WEAR":
      return "착용";
  }
};

export const getHumanGender = (value: string) => {
  switch (value) {
    case "MALE":
      return "남자";
    case "FEMALE":
      return "여자";
  }
};

export const getGroup = (value: string) => {
  switch (value) {
    case "tops":
      return "상의";
    case "bottoms":
      return "하의";
    case "outer":
      return "아우터";
    case "onePiece":
      return "원피스";
  }
};

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const year: number = new Date().getFullYear();

export const getYear: any = String(year);

export const getMonth: number = new Date().getMonth() + 1;

/**
 * 생일은 입력한 위치, 시점을 그대로 반영해야 하므로 local 시간을
 * 계산하여 리턴한다.
 *
 * @param {*} value
 * @param {*} format
 */
export const getDate = (value: Date, format: string) => {
  return dayjs.utc(value).local().format(format);
};

export const getAge = (value: Date) => {
  const myYear: any = dayjs.utc(value).local().format('YYYY');
  const age = getYear - myYear;
  return age;
};

import React from "react";
import { Page } from "../layout";
import { t } from "../lib";

const Dashboard = () => {
  return (
    <Page
      layout="NOT_FOUND"
      title=""
      helmet={{ title: t("Home"), description: "TS-REACT Home" }}
    >
      <img src="/img_color.svg" alt="logo" />
    </Page>
  );
};

export default Dashboard;

const messages = {
  Actions: "작업",
  Add: "추가",
  Address: "주소",
  AddressEmptyMessage: "등록된 주소 없음",
  Answer: "답변",
  AppInfo: "앱 정보",
  AppName: "홈스장",
  Author: "작성자",
  Batch: "배치",
  Board: "게시판",
  Billboard: "전광판",
  Birthday: "생년월일",
  Cancel: "취소",
  ChangePassword: "비밀번호 변경",
  Close: "닫기",
  Comment: "댓글",
  Company: "업체",
  Color: "털색상",
  Count: "수량",
  CreatedAt: "등록 일시",
  CurrentPassword: "현재 비밀번호",
  Dashboard: "대시보드",
  Data: "데이터",
  Date: "일시",
  Delivery: "배송",
  DeliveryCode: "송장번호",
  DeliveryCodeDoneMessage: "송장번호가 등록되었습니다.",
  DeliveryCompany: "배송업체",
  DeliveryRequest: "배송요청사항",
  DeliveryUrl: "배송조회URL",
  Description: "설명",
  Details: "상세",
  DeliveryFee: "배송비",
  Display: "표시",
  DOG: "강아지",
  EditName: "이름 수정",
  EngName: "영문명",
  EmailAddress: "이메일주소",
  EmptyListMessage: "데이터가 없습니다.",
  Faq: "FAQ",
  Feature: "특징",
  FeeThreshold: "배송정책",
  Female: "암컷",
  FirstName: "이름",
  ForgotPassword: "비밀번호를 잊으셨나요?",
  ForgotPasswordDoneMessage: "비밀번호 초기화 안내 메일이 발송되었습니다.",
  Gender: "성별",
  General: "일반",
  Group: "분류",
  GuideUrl: "촬영가이드URL",
  Hidden: "숨김",
  HiddenDoneMessage: "숨김 상태를 변경했습니다.",
  Home: "홈",
  HomeUrl: "안내화면URL",
  ID: "ID",
  InputBreedKeywords: "키워드를 입력하세요",
  InputDeliveryCode: "송장번호를 입력하세요.",
  InputOptionName: "옵션명을 입력하세요. ex)사이즈,색상 등",
  InputOptionPrice: "옵션의 추가금액을 입력하세요.기본값은 0입니다.",
  InputOptionValue: "옵션의 값을 입력하세요.",
  Insert: "등록",
  JuminNumber: "주민등록번호",
  Keyword: "키워드",
  LastName: "성",
  List: "목록",
  Like: "좋아요",
  MaterialBy: "소재별",
  Manager: "담당자",
  Male: "수컷",
  Mall: "쇼핑몰",
  Mediafile: "미디어파일",
  Message: "내용",
  MileageAdd: "적립된 포인트 금액",
  MileageFree: "적립금 지급 여부",
  MileageUse: "사용한 적립금 금액",
  MoveToHome: "홈화면으로 이동",
  Name: "이름",
  NameChangedMessage: "이름을 성공적으로 변경하였습니다.",
  Neuter: " 중성화 여부",
  NeuterNo: "중성화 안함",
  NeuterYes: "중성화 함",
  NewPassword: "새 비밀번호",
  NickName: "닉네임",
  No: "아니오1",
  Notice: "공지",
  NoMediafiles: "없음",
  Order: "주문",
  OrderCode: "주문번호",
  OrderItem: "주문상품",
  OrderPrice: " 주문금액",
  OrderUser: "주문한 회원 정보",
  OrderedAt: "주문일시",
  Origin: "나라",
  OptionValueHelpMessage: "값을 하나씩 입력하세요. ex)S,M,L,빨강,파랑 등",
  Options: "옵션",
  OptionValue: "옵션값",
  OptionsPrice: "추가금액",
  OptionYes: "옵션있음",
  OptionNo: "옵션없음",
  Owner: "보호자",
  Okay: "확인",
  Partner: "협력업체",
  Pass: "통과",
  Password: "비밀번호",
  PasswordChangedMessage: "비밀번호를 성공적으로 변경하였습니다.",
  PasswordConfirm: "비밀번호 재입력",
  Pay: "결제",
  PayCancel: "주문 취소",
  PayCancelMessage:
    "주문을 취소하시겠습니까?\n주문취소하면 결제도 자동적으로 취소됩니다.",
  PayCancelDoneMessage: "주문을 취소했습니다.",
  PayMethod: "결제 수단",
  PayPrice: "결제금액",
  PayTid: "아임포트 거래 코드",
  PayReceiptUrl: "영수증 URL",
  Phone: "전화번호",
  PhoneType: "전화번호 유형",
  PinLevel: "상단 노출 순위",
  PinLevelChangeDoneMessage: "노출 순위를 변경했습니다.",
  Post: "게시글",
  Point: "포인트",
  PostClick: "클릭",
  Position: "직책",
  Price: "가격",
  Preview: "미리보기",
  Product: "상품",
  ProductCode: "상품코드",
  ProductStatusChangeDoneMessage: "상품의 판매 상태를 변경하였습니다.",
  ProductMileageChangeDoneMessage: "적립금 지급 상태를 변경하였습니다.",
  ProductName: "상품명",
  ProductNewDoneMessage: "상품 등록을 완료하였습니다.",
  Profile: "프로필",
  Qna: "Q&A",
  Ready: "대기",
  Reciver: "받는사람 정보",
  Referer: "소속회사",
  RetailPrice: "소비자 가격",
  Reject: "반려",
  RejectMessage: "반려 메세지",
  Remove: "삭제",
  RemoveConfirmMessage: "정말 삭제하시겠습니까?",
  RequiredValue: "필수입니다.",
  ResetPassword: "비밀번호 초기화",
  ResetPasswordDoneMessage: "비밀번호를 새 비밀번호로 설정하였습니다.",
  Role: "권한",
  RoleEditDoneMessage: "수정된 권한 정보를 성공적으로 저장하였습니다.",
  RoleNewDoneMessage: "새로운 권한이 등록되었습니다.",
  RoleRemoveConfirmMessage: "선택한 권한을 삭제하시겠습니까?",
  Season: "시즌",
  SeasonBy: "계절별",
  Sequence: "순서",
  Share: "공유",
  SignIn: "로그인",
  SignOut: "로그아웃",
  SignOutConfirmMessage: "로그아웃하시겠습니까?",
  SignUp: "회원 가입",
  SignUpDone: "회원 가입 완료",
  SignUpDoneMessage:
    "회원가입 요청이 성공적으로 완료되었습니다. 메일을 열고 계정을 활성화하십시오.",
  Species: "종",
  Staff: "직원",
  StaffNewDoneMessage: "신규 계정을 등록하였습니다.",
  Stock: "재고",
  StatDaily: "통계",
  StatDailyData: "데이터 일별 전일 통계",
  StatDailyUsers: "참가자 일별 전일 통계",
  StatTotal: "누적 통계",
  StatTotalData: "데이터 일별 누적 통계",
  StatTotalUsers: "참가자 일별 누적 통계",
  Status: "상태",
  StatusUpdateDoneMessage: "상태가 변경되었습니다.",
  Subtitle: "소제목",
  Submit: "제출",
  SupplyPrice: "공급 가격",
  Supplier: "공급자",
  System: "시스템",
  SystemInfo: "시스템 정보",
  SystemSettings: "시스템 설정",
  ThumbNail: "썸네일",
  Title: "제목",
  TotalCount: "총 %{count} 건",
  Type: "유형",
  Update: "수정",
  UpdateDoneMessage: "수정이 완료되었습니다.",
  Upload: "업로드",
  User: "회원",
  UserActivation: "회원 계정 활성화",
  Users: "참가자",
  UserVerificationFailedMessage:
    "계정 활성화에 실패하였습니다. 토큰 문자열이 기한이 지났거나 올바르지 않은 문자열입니다. 확인 후 다시 시도하세요.",
  UserVerifiedMessage: "회원가입이 완료되었고 활성화되었습니다.",
  Yes: "예",
  Height: "키",
  Weight: "몸무게",
};

export default messages;

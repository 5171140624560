import React, { useRef, useState } from "react";
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Typography,
  Popper,
} from "@mui/material";
import { useMutation } from "@apollo/client";

import {
  EVENT_LIST,
  EVENT_VIEW,
  //   EVENT_RUN,
  EVENT_NEW,
  EVENT_UPDATE,
  EVENT_REMOVE,
} from "../../graphql";
import { Table } from "../../components";
import { Page } from "../../layout";
import { ArrowDropDown } from "@mui/icons-material";
import { EventCmd, Site } from "@num4sell/datasources/types";

const options = ["----- 선 택 -----", "무신사 브랜드 가져오기"];

const List = () => {
  const [eventNew] = useMutation(EVENT_NEW, {
    onCompleted: (response) => {
      console.log(response);
      if (!response) return;
    },
  });

  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleClick = () => {
    switch (selectedIndex) {
      case 1:
        eventNew({
          variables: { input: { cmd: EventCmd.BRAND, target: Site.MUSINSA } },
        });
        break;
      default:
        break;
    }
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const headers = [
    { id: "_id", label: "ID" },
    { id: "title", label: "Title" },
    { id: "thumbNail", label: "ThumbNail" },
    { id: "description", label: "Description" },
  ];

  const form = {
    name: "Event",
    crud: {
      c: EVENT_NEW,
      r: EVENT_VIEW,
      u: EVENT_UPDATE,
      d: EVENT_REMOVE,
    },
    columns: [
      { id: "title", label: "Title" },
      { id: "thumbNail", label: "ThumbNail" },
      { id: "description", label: "Description" },
    ],
  };

  return (
    <Page
      title={"이벤트"}
      pageTitle={
        <Typography variant="h4">
          {"이벤트"} <small>{"목록"}</small>
        </Typography>
      }
    >
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="split button"
      >
        <Button onClick={handleClick}>{options[selectedIndex]}</Button>
        <Button
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDown />
        </Button>
      </ButtonGroup>

      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      //   disabled={index === 2}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <Table headers={headers} form={form} query={{ eventList: EVENT_LIST }} />
    </Page>
  );
};

export default List;

import React, { useEffect } from "react";
import { useMutation } from "@apollo/client";
import { filterGraphQLErrors } from "../../lib";
import { VERIFY_USER } from "../../graphql";
import VerifyUser from "./VerifyUser";
import { useRouteMatch } from "react-router-dom";

interface MatchParams {
  token: string;
}

const VerifyUserContainer = (props: any) => {
  const match = useRouteMatch<MatchParams>("/verify-user/:token");
  const token = match?.params?.token;

  const [verifyUser, { loading, error }] = useMutation(VERIFY_USER, {
    onCompleted: (response) => {
      if (!response?.verifyUser) return;
    },
  });

  useEffect(() => {
    verifyUser({ variables: { token } });
  }, [verifyUser, token]);

  if (error) {
    // console.log("verifyUser error", JSON.stringify(error, null, " "));
  }

  return (
    <VerifyUser submitting={loading} errors={filterGraphQLErrors(error)} />
  );
};

export default VerifyUserContainer;

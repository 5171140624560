import _ from "lodash";

import { IUser } from "@num4sell/datasources/types";

export const getUserName = (user: IUser): string | undefined => {
  const name = user.name;

  return !_.isEmpty(name) ? name : user.email;
};

export const getUserAvatar = (user: IUser): string | undefined => {
  return user.email;
};

export const getUserRoles = (user: IUser): string[] | undefined => {
  const rtn: string[] = [];
  user?.roles?.map((item) => rtn.push(item._id));
  return rtn;
};

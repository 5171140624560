import { createTheme, Theme } from "@mui/material/styles";

export const DefaultTheme: Theme = createTheme({
  palette: {
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#000000",
    },
  },
});

export const DarkTheme: Theme = createTheme({
  palette: {
    mode: "dark",
  },
});
